import { Component, OnInit } from '@angular/core';
import { ConexionService } from 'src/app/services/conexion.service';

@Component({
  selector: 'app-lista-add',
  templateUrl: './lista-add.component.html',
  styleUrls: ['./lista-add.component.css']
})
export class ListaAddComponent implements OnInit {

  heroe: any = {
    nombre: ''
  };

  constructor(private servicio: ConexionService) {
    servicio
  }

  ngOnInit() {
  }

  agregar(){
    this.servicio.addHeroe(this.heroe);
    this.heroe.nombre = '';
  }
}
