import { Component, OnInit } from '@angular/core';
import { ConexionService } from 'src/app/services/conexion.service';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.css']
})
export class ListaComponent implements OnInit {

  heroes: any;
  editarHeroe: any = {
    name: ''
  }

  constructor(private conexion: ConexionService) {
    conexion.listaHeroes().subscribe(item => {
      this.heroes = item;
      console.log(this.heroes);
    });
  }

  ngOnInit() {
  }

  eliminar(heroe) {
    this.conexion.eliminarHeroe(heroe);
  }

  editar(heroe) {
    this.editarHeroe = heroe;
  }

  agregarHeroeEditado(){
    this.conexion.editarHeroe(this.editarHeroe);
  }

}
