import { Injectable } from '@angular/core';

import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

export interface Heroe { 
  nombre: string; 
  casa: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConexionService {

  private heroesCollection: AngularFirestoreCollection<Heroe>; 
  heroes: Observable<Heroe[]>;
  private itemDoc: AngularFirestoreDocument<Heroe>;

  constructor(private afs: AngularFirestore) { 
    this.heroesCollection = afs.collection<Heroe>('heroes');
    //this.heroes = this.heroesCollection.valueChanges();
    this.heroes = this.heroesCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Heroe;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  listaHeroes(): Observable<Heroe[]>{
    return this.heroes;
  }

  addHeroe(heroe: Heroe) {
    this.heroesCollection.add(heroe);
  }

  eliminarHeroe(heroe) {
    this.itemDoc = this.afs.doc<Heroe>(`heroes/${heroe.id}`);
    this.itemDoc.delete();
  }

  editarHeroe(heroe) {
    this.itemDoc = this.afs.doc<Heroe>(`heroes/${heroe.id}`);
    this.itemDoc.update(heroe);
  }
}
