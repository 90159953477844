export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA5j1Gu0qxesEPzeIgGVJciK_bPdJLp2gE",
    authDomain: "herosapp-caee7.firebaseapp.com",
    databaseURL: "https://herosapp-caee7.firebaseio.com",
    projectId: "herosapp-caee7",
    storageBucket: "herosapp-caee7.appspot.com",
    messagingSenderId: "726097181949"
  }
};